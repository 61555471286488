import { template as template_25025a24af6f4d32a6ebfdc2afafc42d } from "@ember/template-compiler";
const FKText = template_25025a24af6f4d32a6ebfdc2afafc42d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
