import { template as template_c05c368a48a048649135bbe454d1d391 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c05c368a48a048649135bbe454d1d391(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
