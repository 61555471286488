import { template as template_3b6e75109cff40b6a7275785a649d7d6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3b6e75109cff40b6a7275785a649d7d6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
